type LoginInputsProps = {
    label: {
        ar: string,
        en: string
    },
    name: string,
    type: string,
    control: string
}
const LoginInputs: LoginInputsProps[] = [
    {
        label: {
            en: "Email",
            ar: "البريد الإلكتروني"
        },
        name: "email",
        type: "email",
        control: "input"
    },
    {
        label: {
            en: "Password",
            ar: "كلمة المرور"
        },
        name: "password",
        type: "password",
        control: "input"
    }
]
export default LoginInputs