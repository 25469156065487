import { AddUserModal, BoxTitle, BoxWithShadow, DynamicTable, EditUserModal, Loading, MainBtn, Modal, ResetPasswordModal } from "../../../Components";
import useGet from "../../../Custom Hooks/useGet";
import UserService from "../../../Services/UserService";
import "./Users.css"
import { UsersCols } from "../../../Assets/Data";
import { UserContext } from "../../../Context/UserContext";
import { useContext, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell } from 'recharts';
const Users = () => {
    const { isLoading } = useContext(UserContext)
    const { dependancy, userId } = useContext(UserContext)
    const [dataSource, loading] = useGet(UserService.getAll(), undefined, dependancy)
    const [generalStatics, loadingStatics] = useGet(UserService.getGeneralStatistics())
    const [usersOption, setUsersOption] = useState<{ id: number, name: string }[]>()
    const { i18n, t } = useTranslation()
    const [showAddUser, setShowAddUser] = useState(false);
    const handleCloseAddUser = () => setShowAddUser(false);
    const handleShowAddUser = () => setShowAddUser(true);
    const [showEditUser, setShowEditUser] = useState(false);
    const handleCloseEditUser = () => setShowEditUser(false);
    const handleShowEditUser = () => setShowEditUser(true);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const handleCloseResetPassword = () => setShowResetPassword(false);
    const handleShowResetPassword = () => setShowResetPassword(true);
    const navigate = useNavigate()
    const { UsersColsData } = UsersCols(handleShowEditUser, handleShowResetPassword)

    useEffect(() => {
        if (dataSource) {
            const filter = dataSource?.data?.data?.map((item: any) => {
                return {
                    "id": item.id,
                    "name": item.name,
                }
            }
            )
            setUsersOption(filter)
        }
        console.log(usersOption)
    }, [dataSource])

    const data = [
        { name: 'On-site', value: generalStatics?.data?.data?.on_site },
        { name: 'Remotely', value: generalStatics?.data?.data?.remotely },
    ];
    const COLORS = ['white', 'rgba(255, 255, 255, 0.528)'];
    return (
        <>

            <BoxWithShadow additionalClass={`holidays-con container-fluid mb-3 hhh ${i18n.language == "ar" && "ar"}`}>
                <BoxTitle title={i18n.language == "ar" ? "الموظفين" : "Employee"} />
                <div className="sales-con-flex">
                    <div className="sales-con-flex-col">
                        <p>{generalStatics?.data?.data?.on_site} <span className="percentage-circle percentage-circle-one"></span></p>
                        <p>{i18n.language == "ar" ? "في المكتب" : "On-Site"} </p>
                    </div>
                    <div className="sales-con-flex-col">
                        <p>{generalStatics?.data?.data?.remotely} <span className="percentage-circle percentage-circle-two"></span></p>
                        <p>{i18n.language == "ar" ? "عن بعد" : "Remotely"}</p>
                    </div>
                    <PieChart width={170} height={170}>
                        <Pie
                            data={data}
                            labelLine={false}
                            outerRadius={80} // Outer radius of the pie chart
                            innerRadius={40} // Inner radius to create a hole
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
            </BoxWithShadow>
            <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"}`}>
                <div className="flex-between mb-5">
                    <BoxTitle title={t("user-mangement")} />
                    <MainBtn title={t("AddUser")} onClick={handleShowAddUser} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={UsersColsData}
                />
                <AddUserModal
                    show={showAddUser}
                    handleClose={handleCloseAddUser}
                    userOptions={usersOption}
                />
                <EditUserModal
                    userOptions={usersOption}
                    show={showEditUser}
                    handleClose={handleCloseEditUser}
                />
                <ResetPasswordModal
                    show={showResetPassword}
                    handleClose={handleCloseResetPassword} />
                <ToastContainer />
            </BoxWithShadow>

        </>

    )
}

export default Users