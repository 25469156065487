import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import "./InputImage.css";
import { RiPencilFill } from "react-icons/ri";
import { Base64 } from "../../Helpers";
import { useTranslation } from 'react-i18next';
type InputImageProps = {
    name: string,
    label: {
        ar: string,
        en: string
    },
}
const InputImage: FC<InputImageProps> = ({ name, label }) => {
    const { t, i18n } = useTranslation()
    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
        if (event.target.files && event.target.files.length > 0) {
            try {
                const image = await Base64(event.target.files[0]);
                // Use Formik's setFieldValue to update the field's value with the base64 string
                form.setFieldValue('image', image); // 'img' is the name of your field
            } catch (error) {
                console.error("Failed to process image:", error);
                // Optionally handle the error, e.g., show a notification to the user
            }
        }
    };

    return (

        <>
            <Field name="img">
                {({ field, form }: FieldProps<any>) => (
                    <>
                        <div className='img-label'>{i18n.language == "ar" ? label.ar : label.en}</div>
                        <label htmlFor="imgUpload" className="img-input-con bg-full">
                            <div className="change-img-btn flex-center">
                                <RiPencilFill className="icon" />
                            </div>
                            <input id="imgUpload" {...field} type="file" onChange={(e) => handleImageChange(e, form)} />
                        </label>
                    </>
                )}
            </Field>
            <Field name={name} type="hidden" />
        </>
    );
};

export default InputImage;