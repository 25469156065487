import { ErrorMessage, Field, Form, Formik } from "formik"
import "./ResetPasswordModal.css"
import BoxTitle from "../BoxTitle/BoxTitle"
import ResetPasswordSchema from "../../Utils/Validations/ResetPasswordSchema"
import { useTranslation } from "react-i18next"
import TextError from "../TextError/TextError"
import { useContext } from "react"
import { UserContext } from "../../Context/UserContext"
import usePost from "../../Custom Hooks/usePost"
import UserService from "../../Services/UserService"
import { GetLoadingText } from "../../Helpers"
import { Modal } from "react-bootstrap"

const initialValues = {
    password: "",
    password_confirmation: ""
}
const ResetPasswordModal = ({ show, handleClose }: any) => {
    const { t, i18n } = useTranslation()
    const { userId } = useContext(UserContext)
    const [handleSubmit, loading] = usePost(UserService.resetPassword, {
        success: t("ResetPasswordSuccessfully"),
        error: t("Somethingwentwrong")
    }, () => {
        window.location.reload()
    })
    const onSubmit = (values: any, onSubmitProps: any) => {
        handleSubmit({ user_id: userId, ...values }, onSubmitProps)
    }
    const btnTextObjAdd = {
        null: t("ResetPassword"),
        success: t("ResetPasswordSuccessfully"),
        faild: t("Somethingwentwrong"),
        loading: t("loading")
    }
    const text = GetLoadingText(loading, btnTextObjAdd)
    return (
        <Modal show={show} onHide={handleClose} id="EditUser" className="users-modal" centered>

            <div className="modal-header">
                <BoxTitle title={t("ResetPassword")} />
                <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={initialValues}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={onSubmit}
                >

                    {(formik) => (
                        <Form >
                            <div className="form-control dynamic-input">
                                <label htmlFor="password" className="form-label">{i18n.language == "ar" ? "كلمة المرور" : "Password"}</label>
                                <Field name="password" id="password" className="form-control" />
                                <ErrorMessage name="password" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="password_confirmation" className="form-label">{i18n.language == "ar" ? "تأكيد كلمة المرور" : "password Confirmation"}</label>
                                <Field name="password_confirmation" id="password_confirmation" className="form-control" />
                                <ErrorMessage name="password_confirmation" component={TextError} />
                            </div>
                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {text}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default ResetPasswordModal