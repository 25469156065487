import { FC } from "react"
import "./FormikControl.css"
import { Input, InputImage, Select } from "../"
import { SelectOptionsTypes } from "../../Models/SelectOptionsTypes"


type FormikControlProps = {
    control: string,
    name: string,
    label: {
        ar: string,
        en: string
    },
    type?: string,
    options?: SelectOptionsTypes[]
}
const FormikControl: FC<FormikControlProps> = ({ name, label, control, type, options, ...rest }) => {
    switch (control) {
        case "input": return <Input name={name} label={label} type={type} {...rest} />
        case "img": return <InputImage name={name} label={label} />
        case "select": return <Select name={name} label={label} options={options} {...rest} />
        default: return <></>
    }
}

export default FormikControl