import { Outlet } from 'react-router-dom'
import UserContextProvider from './Context/UserContext'


const Root = () => {
    return (
        <div className="App">
            <UserContextProvider>
                <Outlet />

            </UserContextProvider>
        </div>
    )
}

export default Root