import { RouteObject } from "react-router-dom";
import { MainLayout, WithLinksLayout } from "../../Components";
import { MainLinks } from "../../Assets/Data";
import { ChangePassword, EditProfile, FileMangement, Holidays, HrDashboard, Index, MainPage, Profile, Users } from "../../Pages";
import PrivateRoutes from "../../Utils/PrivateRoutes/PrivateRoutes";

const DashboardRoutes: RouteObject[] = [

    {
        Component: PrivateRoutes,
        children: [
            {
                path: "/dashboard",
                element: WithLinksLayout({
                    Component: MainLayout,
                    linksArray: MainLinks, // Use your specific links array here
                }),
                children: [
                    {
                        path: "Users",
                        Component: Users
                    },
                    {
                        path: "Profile-Mangement",
                        Component: Index,
                        children: [
                            {
                                index: true,
                                Component: Profile,

                            },
                            {
                                path: "edit-profile",
                                Component: EditProfile,

                            },
                            {
                                path: "File-Mangement",
                                Component: FileMangement
                            },
                            {
                                path: "Change-Password",
                                Component: ChangePassword
                            }
                        ]
                    },
                    {
                        index: true,
                        Component: MainPage
                    },

                    {
                        path: "hrdashboard",
                        Component: HrDashboard
                    },
                    {
                        path: "Holidays",
                        Component: Holidays
                    },
                    {
                        path: "userProfile/:id",
                        Component: Index,
                        children: [
                            {
                                index: true,
                                Component: Profile,

                            },
                            {
                                path: "edit-profile",
                                Component: EditProfile,

                            },
                            {
                                path: "File-Mangement",
                                Component: FileMangement
                            },

                            {
                                path: "Change-Password",
                                Component: ChangePassword
                            }

                        ]

                    },
                ]
            }
        ]
    }

]
export default DashboardRoutes