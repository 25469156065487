import React, { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import Cookies from "js-cookie"




export const UserContext = createContext<{
    token: string;
    avatar: string;
    setDepandancy: Dispatch<SetStateAction<boolean>>;
    dependancy: boolean;
    name: string;
    getUserId: any;
    userId: number;
    loginedUserId: string;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}>({
    token: "",
    avatar: "",
    setDepandancy: () => { },
    dependancy: false,
    name: "",
    getUserId: () => { },
    userId: 1,
    loginedUserId: "",
    isLoading: true,
    setIsLoading: () => { }
})
type UserContextProviderProps = {
    children: React.ReactNode
}

const UserContextProvider: FC<UserContextProviderProps> = ({ children }) => {
    const [token, setToken] = useState(Cookies.get("token") || "")
    const [avatar, setAvatar] = useState(localStorage.getItem("avatar") || "")
    const [name, setName] = useState(localStorage.getItem("name") || "")
    const [dependancy, setDepandancy] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [userId, setUserId] = useState<number>(1)
    const [loginedUserId, setLoginedUserId] = useState(Cookies.get("id") || "")
    const getUserId = (id: number) => {
        setUserId(id)
    }
    return (
        <UserContext.Provider value={{
            token: token,
            avatar,
            setDepandancy,
            dependancy,
            name,
            getUserId,
            userId,
            loginedUserId,
            isLoading,
            setIsLoading
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
