import "./DropDownMenu.css"
import { DynamicDropDownItems } from "../"
import { FC } from "react"
import { DropDownDataProps } from "../../Models/DropDownProps"
type DropDownMenuProps = {
    DropDownItems: DropDownDataProps[],
    record?: any,
    handleShowEditUser?: any,
    handleShowResetPassword?: any
}
const DropDownMenu: FC<DropDownMenuProps> = ({ DropDownItems, record, handleShowEditUser, handleShowResetPassword }) => {

    const items = DropDownItems.map(item => <DynamicDropDownItems
        item={item}
        record={record}
        handleShowEditUser={handleShowEditUser}
        handleShowResetPassword={handleShowResetPassword}
    />)
    return (
        <ul className="dropdown-menu">
            {items}
        </ul>
    )
}

export default DropDownMenu