import "./Loading.css"
import Logo from "../../Assets/Images/AETC Logo white.svg"
const Loading = () => {
    return (
        <div
            className="loading-container"
        >
            <img src={Logo} alt="logo" />
            <p>Loading <div className="spinner-border" role="status">
            </div></p>

        </div>
    )
}

export default Loading