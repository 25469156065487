import axios from "axios";
import Cookies from "js-cookie"
const config = {
    baseURL: "https://crmapi.aetc.ae/api",
    // withXSRFToken: true,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
};
const fileconfig = {
    baseURL: "https://crmapi.aetc.ae/api",
    // withXSRFToken: true,
    // withCredentials: true,
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};

export const PublicAxios = axios.create(config)
export const AuthAxios = axios.create(config)
export const FileAxios = axios.create(fileconfig)

AuthAxios.interceptors.request.use(async req => {
    const token = Cookies.get("token")
    if (token) req.headers.Authorization = `Bearer ${token}`;
    return req;
})
FileAxios.interceptors.request.use(async req => {
    const token = Cookies.get("token")
    if (token) req.headers.Authorization = `Bearer ${token}`;
    return req;
})