import { DropDownDataProps } from "../../../../Models/DropDownProps"

const TableDropDownData: DropDownDataProps[] = [
    {
        title: {
            en: "View Profile",
            ar: "الملف الشخصي"
        },
        isViewProfile: true
    },
    {
        title: {
            en: "Edit",
            ar: "تعديل"
        },
        isEdit: true
    },
    {
        title: {
            en: "Reset Password",
            ar: "اعادة تعيين كلمة المرور"
        },
        isReset: true
    }
    ,
    {
        title: {
            enDe: "Deactivate",
            arDe: "الغاء تنشيط الحساب",
            enAc: "Active",
            arAc: "تنشيط الحساب"
        },
        isDeactive: true
    },
]
export default TableDropDownData