import "./MainLayout.css"
import { FC, useEffect, useState } from "react"
import { LinksProps } from "../../Models/LinksProps"
import { Outlet, useLocation } from "react-router-dom"
import { MainNav, SideBar } from "../../Sections"
type MainLayoutProps = {
    linksArray: LinksProps[]
}
const MainLayout: FC<MainLayoutProps> = ({ linksArray }) => {

    return (
        <div className="main-layout">
            <div className="row p-0 m-0">
                <SideBar linksArray={linksArray} />
                <div className="col-md-9">
                    <MainNav />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout