import React, { FC } from "react"
import "./BoxWithShadow.css"
type BoxWithShadowProps = {
    additionalClass: string,
    children?: React.ReactNode
}
const BoxWithShadow: FC<BoxWithShadowProps> = ({ additionalClass, children }) => {
    return (
        <div className={`box-with-shadow ${additionalClass}`}>
            {children}
        </div>
    )
}

export default BoxWithShadow