import { FC } from "react"
import "./DynamicTable.css"
import { Table } from "antd"
import { ColsType } from "../../Models/ColsType"
type DynamicTableProps = {
    loading: boolean,
    columns: ColsType[],
    dataSource: any,
    onClick?: (record: any) => any
}
const DynamicTable: FC<DynamicTableProps> = ({ dataSource, columns, loading, onClick }) => {
    return (
        <div className="table-con">
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => onClick && onClick(record.id),
                    };
                }}
            />
        </div>
    )
}

export default DynamicTable