import { AuthAxios } from "../../Services/AxiosHandler"

class LeavesServices {
    static endPoint = "employee-leaves"
    static get() {
        return AuthAxios.get(`${LeavesServices.endPoint}`)
    }
    static post(body: any) {
        return AuthAxios.post(`${LeavesServices.endPoint}/store`, body)
    }

}
export default LeavesServices