import { AuthAxios, FileAxios } from "./AxiosHandler"

class UserService {
    static endPoint = "users"
    static getAll() {
        return AuthAxios.get(`${UserService.endPoint}`)
    }
    static deactiveUser(id: number) {
        return AuthAxios.get(`${UserService.endPoint}/change-status/${id}`)
    }
    static addUser(body: any) {
        return FileAxios.post(`${UserService.endPoint}/store`, body)
    }
    static updateUser(body: any) {
        return FileAxios.post(`${UserService.endPoint}/update`, body)
    }
    static getUserById(id: any) {
        return AuthAxios.get(`${UserService.endPoint}/${id}`)
    }
    static getUser() {
        return AuthAxios.get(`${UserService.endPoint}/get-user-data`)
    }
    static resetPassword(body: any) {
        return AuthAxios.post(`${UserService.endPoint}/reset-password`, body)
    }
    static getFilesById(id: any) {
        return AuthAxios.get(`${UserService.endPoint}/files/index/${id}`)
    }
    static deleteFileById(id: any) {
        return AuthAxios.get(`${UserService.endPoint}/files/delete/${id}`)
    }
    static addFile(body: any) {
        return FileAxios.post(`${UserService.endPoint}/files/store`, body)
    }
    static getGeneralStatistics() {
        return AuthAxios.get(`${UserService.endPoint}/get-general-statistics`)
    }
    static UpdateProfile(body: any) {
        return AuthAxios.post(`${UserService.endPoint}/update-profile`, body)
    }
    static ChangePassword(body: any) {
        return AuthAxios.post(`${UserService.endPoint}/change-password`, body)
    }
}
export default UserService