import { FC } from "react"
import "./TextError.css"
type TextErrorProps = {
    children?: string
}
const TextError: FC<TextErrorProps> = ({ children }) => {
    return (
        <div className="text-error">
            {children}
        </div>
    )
}

export default TextError