import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, Loading, MainBtn } from "../../../../Components"
import useGet from "../../../../Custom Hooks/useGet"
import UserService from "../../../../Services/UserService"
import "./Profile.css"
import { useParams } from "react-router-dom"
import { useContext } from "react"
import { UserContext } from "../../../../Context/UserContext"

const Profile = () => {
    const params = useParams()
    const [data, loading] = useGet(params.id != null ? UserService.getUserById(params.id) : UserService.getUser())
    const { i18n, t } = useTranslation()
    const { isLoading, } = useContext(UserContext)
    return (
        <>
            {isLoading == true ? <Loading /> : <>
                <BoxWithShadow additionalClass="profile-details container-fluid">
                    <div className="flex-between">
                        <BoxTitle title={i18n.language == "ar" ? "معلومات الحساب" : "Profile Details"} />
                        {/* <MainBtn title={i18n.language == "ar" ? "تعديل الحساب" : "Edit Profile"} /> */}
                    </div>
                    <div className="flex-start-start-col profile-details-box">
                        <div className="row">
                            <div className="col">
                                <h6>{t("name")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.name}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>{t("email")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>{t("DateOfBirth")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.date_of_birth}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>{t("mobile")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.mobile}</p>
                            </div>
                        </div>
                        {data?.data?.data?.type == "On-site" && <div className="row">
                            <div className="col">
                                <h6>visa id number</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.visa_id_number}</p>
                            </div>
                        </div>}
                        {/* <div className="row">
                            <div className="col">
                                <h6>{t("HomeAddress")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.address_home}</p>
                            </div>
                        </div> */}
                        {data?.data?.data?.type == "On-site" && <div className="row">
                            <div className="col">
                                <h6>visa expiry date</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.visa_expiry_date}</p>
                            </div>
                        </div>}
                        {data?.data?.data?.type == "On-site" && <div className="row">
                            <div className="col">
                                <h6>labor card issue date</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.labor_card_issue_date}</p>
                            </div>
                        </div>}
                        {data?.data?.data?.type == "On-site" && <div className="row">
                            <div className="col">
                                <h6>labor card expiry date</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.labor_card_expiry_date}</p>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col">
                                <h6>Blood Type</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">{data?.data?.data?.blood_type}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>{t("OfficialDateOfEmployment")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">
                                    {data?.data?.data?.official_date_of_employment}
                                </p>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <h6>{t("type")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">
                                    {data?.data?.data?.type}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>{t("HomeCountry")}</h6>
                            </div>
                            <div className="col">
                                <p className="m-0">
                                    {data?.data?.data?.home_country}
                                </p>
                            </div>
                        </div> */}
                    </div>
                </BoxWithShadow>
            </>}
        </>

    )
}

export default Profile