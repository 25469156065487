import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, Loading, MainBtn, TextError } from "../../../../Components"
import "./EditProfile.css"
import { useParams } from "react-router-dom"
import { ErrorMessage, Field, Form, Formik } from "formik"
import usePost from "../../../../Custom Hooks/usePost"
import UserService from "../../../../Services/UserService"
import { useContext, useEffect, useState } from "react"
import useGet from "../../../../Custom Hooks/useGet"
import { ToastContainer } from "react-toastify"
import { UserContext } from "../../../../Context/UserContext"
import DatePicker from "react-datepicker"
import moment from "moment"
const EditProfile = () => {

    const params = useParams()
    const { i18n, t } = useTranslation()
    console.log(params.id != null ? UserService.updateUser : UserService.UpdateProfile)
    const [handleSubmit, loading] = usePost(params.id != null ? UserService.updateUser : UserService.UpdateProfile, {
        success: t("EditUserSuccessfully"),
        error: t("Somethingwentwrong")
    })
    const { loginedUserId, isLoading } = useContext(UserContext)
    const [data,] = useGet(params.id != null ? UserService.getUserById(params.id) : UserService.getUser())


    const initialValues = {
        name: data?.data?.data?.name || "",
        type: data?.data?.data?.type || "",
        date_of_birth: data?.data?.data?.date_of_birth || "",
        email: data?.data?.data?.email || "",
        mobile: data?.data?.data?.mobile || "",
        nationality: data?.data?.data?.nationality || "",
        home_country: data?.data?.data?.home_country || "",
        address_home: data?.data?.data?.address_home || "",
        official_date_of_employment: data?.data?.data?.official_date_of_employment || "",
        employer: data?.data?.data?.employer || "",
        visa_id_number: data?.data?.data?.visa_id_number || "",
        visa_expiry_date: data?.data?.data?.visa_expiry_date || "",
        labor_card_issue_date: data?.data?.data?.labor_card_issue_date || "",
        labor_card_expiry_date: data?.data?.data?.labor_card_expiry_date || "",
        blood_type: data?.data?.data?.blood_type || "",
    }


    const onSubmit = (values: any, onSubmitProps: any) => {
        if (params.id != null) {
            handleSubmit({ ...values, id: params.id }, onSubmitProps)
        }
        else {
            console.log(values)
            handleSubmit({ ...values, id: loginedUserId }, onSubmitProps)
        }
    }
    return (
        <>
            {isLoading == true ? <Loading /> : <BoxWithShadow additionalClass="profile-details container-fluid edit-profile">
                <div className="flex-between">
                    <BoxTitle title={i18n.language == "ar" ? "معلومات الحساب" : "Profile Details"} />
                </div>
                <div className="flex-start-start-col profile-details-box">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {(formik) => (
                            <Form>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("name")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="name" id="name" className="form-control" />
                                            <ErrorMessage name="name" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("email")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="email" id="email" className="form-control" />
                                            <ErrorMessage name="email" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("employer")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="employer" id="employer" className="form-control" />
                                            <ErrorMessage name="employer" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("DateOfBirth")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="date_of_birth" id="date_of_birth" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("date_of_birth", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="date_of_birth" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("mobile")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="mobile" id="mobile" className="form-control" />
                                            <ErrorMessage name="mobile" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col">
                                        <h6>{t("HomeAddress")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="address_home" id="address_home" className="form-control" />
                                            <ErrorMessage name="address_home" component={TextError} />
                                        </div>
                                    </div>
                                </div> */}
                                {data?.data?.data?.type == "On-site" && <div className="row">
                                    <div className="col">
                                        <h6>visa id number</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="visa_id_number" id="visa_id_number" className="form-control" />
                                            <ErrorMessage name="visa_id_number" component={TextError} />
                                        </div>
                                    </div>
                                </div>}
                                {data?.data?.data?.type == "On-site" && <div className="row">
                                    <div className="col">
                                        <h6>visa_expiry_date</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="visa_expiry_date" id="visa_expiry_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("visa_expiry_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="visa_expiry_date" component={TextError} />
                                        </div>
                                    </div>
                                </div>}
                                {data?.data?.data?.type == "On-site" && <div className="row">
                                    <div className="col">
                                        <h6>labor card issue date</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="labor_card_issue_date" id="labor_card_issue_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("labor_card_issue_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="labor_card_issue_date" component={TextError} />
                                        </div>
                                    </div>
                                </div>}
                                {data?.data?.data?.type == "On-site" && <div className="row">
                                    <div className="col">
                                        <h6>labor card expirty date</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="labor_card_expiry_date" id="labor_card_expiry_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("labor_card_expiry_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="labor_card_expiry_date" component={TextError} />
                                        </div>
                                    </div>
                                </div>}
                                {/* <div className="row">
                                    <div className="col">
                                        <h6>{t("Nationality")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="nationality" id="nationality" className="form-control" />
                                            <ErrorMessage name="nationality" component={TextError} />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("OfficialDateOfEmployment")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="official_date_of_employment" id="official_date_of_employment" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("official_date_of_employment", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="official_date_of_employment" component={TextError} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <h6>Blood Type</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control">
                                            <Field as="select" name="type" id="type" className="form-select">
                                                <option disabled value="" hidden>
                                                    Select
                                                </option>
                                                <option value="A+">A+</option>
                                                <option value="A-">A-</option>
                                                <option value="B+">B+</option>
                                                <option value="B-">B-</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                                <option value="AB+">AB+</option>
                                                <option value="AB-">AB-</option>
                                            </Field>
                                            <ErrorMessage name="type" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col">
                                        <h6>{t("type")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control">
                                            <Field as="select" name="type" id="type" className="form-select">
                                                <option disabled value="" hidden>
                                                    Select
                                                </option>
                                                <option value="Remotely">Remotely</option>
                                                <option value="On-site'">On-site</option>
                                            </Field>
                                            <ErrorMessage name="type" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{t("HomeCountry")}</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-control dynamic-input">
                                            <Field name="home_country" id="home_country" className="form-control" />
                                            <ErrorMessage name="home_country" component={TextError} />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">

                                    </div>
                                    <div className="col">

                                        <div className='btn-form-container'>
                                            <button
                                                className='btn btn-primary'
                                                disabled={formik.isSubmitting || !formik.isValid}>
                                                {t("EditUser")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    {/*
                <div className="row">
                    <div className="col">
                        <h6>{t("HomeCountry")}</h6>
                    </div>
                    <div className="col">
                        <p className="m-0">
                            {data?.data?.data?.home_country}
                        </p>
                    </div>
                </div> */}
                </div>
                <ToastContainer />
            </BoxWithShadow>}
        </>

    )
}

export default EditProfile