import { BoxTitle, BoxWithShadow, Loading } from "../../../Components"
import { BarChart, Bar, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, ComposedChart, Line, Area } from 'recharts';
import "./HrDashboard.css"
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { UserContext } from "../../../Context/UserContext";

const HrDashboard = () => {
    const { t, i18n } = useTranslation()
    const { isLoading, setIsLoading } = useContext(UserContext)
    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)

    }, [])
    const data = [
        {
            name: 'Bilal',
            [i18n.language == "ar" ? "الاجازات" : "holidays"]: 10,
        },
        {
            name: 'Mikel',
            [i18n.language == "ar" ? "الاجازات" : "holidays"]: 5,
        },
        {
            name: 'Cj',
            [i18n.language == "ar" ? "الاجازات" : "holidays"]: 3,

        },
        {
            name: 'Ruba',
            [i18n.language == "ar" ? "الاجازات" : "holidays"]: 6,

        },
        {
            name: 'Abdulrahman',
            holidays: 9,
        },
    ];
    // const tasksdata = [

    //     {
    //         name: 'Bilal',
    //         [t("Tasks")]: 3,
    //     },
    //     {
    //         name: 'wedad',
    //         [t("Tasks")]: 9,
    //     },
    //     {
    //         name: 'Ruba',
    //         [t("Tasks")]: 7,
    //     },
    //     {
    //         name: 'Khaled',
    //         [t("Tasks")]: 10,
    //     },
    //     {
    //         name: 'laith',
    //         [t("Tasks")]: 1,
    //     },
    // ];
    // const SalesData = [
    //     { name: t("goverment"), value: 60 },
    //     { name: t("individual"), value: 40 }

    // ];
    const tasksdata = [
        { name: 'Bilal', value: 75 },
        { name: 'Ruba', value: 100 },
        { name: 'Abd', value: 20 },
        { name: 'Khaled', value: 10 },
        { name: 'Mohammed', value: 50 },
        { name: 'Mikel', value: 90 },
    ];

    const SalesData = [
        {
            name: 'Bilal',
            [t("goverment")]: 4000,
            [t("individual")]: 2400,
            amt: 2400,
        },
        {
            name: 'Ruba',
            [t("goverment")]: 3000,
            [t("individual")]: 1398,
            amt: 2210,
        },
        {
            name: 'Abd',
            [t("goverment")]: 2000,
            [t("individual")]: 9800,
            amt: 2290,
        },
        {
            name: 'Mikel',
            [t("goverment")]: 2780,
            [t("individual")]: 3908,
            amt: 2000,
        },
        {
            name: 'Khaled',
            [t("goverment")]: 1890,
            [t("individual")]: 4800,
            amt: 2181,
        },

    ];

    const COLORS = ['white', 'rgba(255, 255, 255, 0.528)'];

    return (
        <>
            {isLoading == true ? <Loading /> : <>

                <BoxWithShadow additionalClass={`holidays-con container-fluid mb-3 ${i18n.language == "ar" && "ar"}`}>
                    <BoxTitle title="Daily holidays" />
                    <ResponsiveContainer width="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barSize={20}
                        >
                            <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                            <YAxis domain={[0, 10]} />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Bar dataKey={i18n.language == "ar" ? "الاجازات" : "holidays"} fill="#0095a9" background={{ fill: '#eee' }} />
                        </BarChart>
                    </ResponsiveContainer>
                </BoxWithShadow>
                <div className="row row-cols-2">
                    <div className="col">

                        <BoxWithShadow additionalClass={`tasks-con ${i18n.language == "ar" && "ar"}`}>
                            <BoxTitle title={t("Sales")} />
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={SalesData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Bar dataKey="individual" fill="#ffffff" />
                                    <Bar dataKey="goverment" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </BoxWithShadow>
                    </div>
                    <div className="col">
                        <BoxWithShadow additionalClass={`tasks container-fluid mb-3 ${i18n.language == "ar" && "ar"}`}>
                            <BoxTitle title={t("Tasks")} />
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                    <Pie
                                        dataKey="value"
                                        isAnimationActive={false}
                                        data={tasksdata}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#0095a9"
                                        label
                                    />
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </BoxWithShadow>
                    </div>
                </div>
            </>}
        </>

    )
}

export default HrDashboard