import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik"
import BoxTitle from "../BoxTitle/BoxTitle"
import "./EditUserModal.css"
import TextError from "../TextError/TextError"
import { useTranslation } from "react-i18next"
import { GetLoadingText } from "../../Helpers"
import EditSchema from "../../Utils/Validations/EditSchema"
import { FC, useContext, useEffect, useState } from "react"
import { UserContext } from "../../Context/UserContext"
import UserService from "../../Services/UserService"
import usePost from "../../Custom Hooks/usePost"
import { RiPencilFill } from "react-icons/ri";
import useGet from "../../Custom Hooks/useGet"
import RolesService from "../../Services/RolesService"
import { Modal } from "react-bootstrap"
import Multiselect from "multiselect-react-dropdown"

type EditUserModalProps = {
    userOptions: {
        id: number,
        name: string
    }[] | undefined,
    show: boolean,
    handleClose: () => void
}
const EditUserModal: FC<EditUserModalProps> = ({ userOptions, show, handleClose }) => {
    const { dependancy, setDepandancy, userId } = useContext(UserContext)
    const [roles,] = useGet(RolesService.getAll())
    const [userDataLoaded, setUserDataLoaded] = useState(false);
    const [userValues, setUserValues] = useState<any>();
    useEffect(() => {
        UserService.getUserById(userId).then((data: any) => {
            setUserValues(data.data.data);
            setUserDataLoaded(true); // Set this to true once data is loaded
        });
        console.log(userValues)
    }, [userId]);
    const initialValues = {
        type: userValues?.type || "", // Default to empty string if null
        email: userValues?.email || "",
        name: userValues?.name || "",
        date_of_birth: userValues?.date_of_birth || "",
        mobile: userValues?.mobile || "",
        nationality: userValues?.nationality || "",
        home_country: userValues?.home_country || "",
        address_home: userValues?.address_home || "",
        employer: userValues?.employer || "",
        official_date_of_employment: userValues?.official_date_of_employment || "",
        role: [],
        iban: userValues?.iban || "",
        address_uae: userValues?.address_uae || "",
        emergency_contact_mobile: userValues?.emergency_contact_mobile || "",
        emergency_contact_person: userValues?.emergency_contact_person || "",
        emergency_contact_relation: userValues?.emergency_contact_relation || "",
        passport_number: userValues?.passport_number || "",
        passport_expiry_date: userValues?.passport_expiry_date || "",
        passport_place_of_issue: userValues?.passport_place_of_issue || "",
        visa_type: userValues?.visa_type || "",
        visa_expiry_date: userValues?.visa_expiry_date || "",
        visa_place_of_issue: userValues?.visa_place_of_issue || "",
        visa_id_number: userValues?.visa_id_number || "",
        labor_card_expiry_date: userValues?.labor_card_expiry_date || "",
        labor_card_issue_date: userValues?.labor_card_issue_date || "",
        bank_name: userValues?.bank_name || "",
        account_holder_name: userValues?.account_holder_name || "",
        account_number: userValues?.account_number || "",
        swift_code: userValues?.swift_code || "",
        manager_id: userValues?.manager_id || "",
        blood_type: userValues?.blood_type || ""

    }
    const { t, i18n } = useTranslation()
    const [handleSubmit, loading] = usePost(UserService.updateUser, undefined, () => {
        setDepandancy(!dependancy)
    })
    console.log(userValues)
    const btnTextObjAdd = {
        null: t("EditUser"),
        success: t("UpdateUserSuccessfully"),
        faild: t("Somethingwentwrong"),
        loading: t("loading")
    }
    const onSubmit = async (values: any, onSubmitProps: any) => {
        // const formData = new FormData();
        // console.log(values)
        // Object.keys(values).forEach(key => {
        //     if (key === 'image') {
        //         if (values[key]) {
        //             formData.append(key, values[key]);
        //         }
        //     } else {
        //         formData.append(key, values[key]);
        //     }
        // });
        // formData.append("id", `${userId}`)
        try {
            handleSubmit(values, onSubmitProps);

        } catch (error) {
            console.error(error);
            // Handle failure
        }
    }
    const text = GetLoadingText(loading, btnTextObjAdd)
    return (
        <Modal show={show} onHide={handleClose} id="EditUser" className="users-modal">
            <div className="modal-header">
                <BoxTitle title={t("EditUser")} />
                <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
                {userDataLoaded == true && <Formik
                    initialValues={initialValues || userValues}
                    validationSchema={EditSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >

                    {(formik) => (
                        <Form encType="multipart/form-data">
                            <>
                                <Field name="image1">
                                    {({ field, form }: FieldProps<any>) => (
                                        <>
                                            <div className='img-label'>{i18n.language == "ar" ? "الصورة الشخصية" : "Avatar"}</div>
                                            <label htmlFor="imgUpload" className={`img-input-con bg-full ${userValues.image == null && "default-img"}`} style={{
                                                backgroundImage: userValues.image != null ? `url(https://crmapi.aetc.ae/${userValues.image})` : ""
                                            }}>
                                                <div className="change-img-btn flex-center">
                                                    <RiPencilFill className="icon" />
                                                </div>
                                                <input id="imgUpload" {...field} type="file" onChange={(e) => formik.setFieldValue("image", e.target.files![0])} />
                                            </label>
                                        </>
                                    )}
                                </Field>
                                <Field name="image" type="hidden" />
                            </>
                            <div className="form-control">
                                <label htmlFor="type">
                                    {i18n.language == "ar" ? "نوع العمل" : "Job Type"}
                                </label>
                                <Field as="select" name="type" id="type" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    <option value="Remotely">Remotely</option>
                                    <option value="On-site">On-site</option>
                                </Field>
                                <ErrorMessage name="type" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="name" className="form-label">{i18n.language == "ar" ? "الاسم" : "Name"}</label>
                                <Field name="name" id="name" className="form-control" />
                                <ErrorMessage name="name" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="email" className="form-label">{i18n.language == "ar" ? "البريد الالكتروني" : "Email"}</label>
                                <Field name="email" id="email" className="form-control" />
                                <ErrorMessage name="email" component={TextError} />
                            </div>
                            {formik.values["type"] != "Remotely" ? <div className="form-control dynamic-input">
                                <label htmlFor="address_uae" className="form-label">{i18n.language == "ar" ? "العنوان في الامارات العربية المتحدة" : "Address UAE"}</label>
                                <Field name="address_uae" id="address_uae" className="form-control" />
                                <ErrorMessage name="address_uae" component={TextError} />
                            </div> : <></>}

                            <div className="form-control dynamic-input">
                                <label htmlFor="date_of_birth" className="form-label">{i18n.language == "ar" ? "تاريخ الولادة" : "Date Of Birth"}</label>
                                <Field name="date_of_birth" id="date_of_birth" className="form-control" />
                                <ErrorMessage name="date_of_birth" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="mobile" className="form-label">{i18n.language == "ar" ? "رقم الهاتف" : "Mobile"}</label>
                                <Field name="mobile" id="mobile" className="form-control" />
                                <ErrorMessage name="mobile" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="nationality" className="form-label">{i18n.language == "ar" ? "الجنسية" : "Nationality"}</label>
                                <Field name="nationality" id="nationality" className="form-control" />
                                <ErrorMessage name="nationality" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="home_country" className="form-label">{i18n.language == "ar" ? "الوطن" : "Home Country"}</label>
                                <Field name="home_country" id="home_country" className="form-control" />
                                <ErrorMessage name="home_country" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">
                                <label htmlFor="address_home" className="form-label">{i18n.language == "ar" ? "العنوان في الوطن" : "Home Address"}</label>
                                <Field name="address_home" id="address_home" className="form-control" />
                                <ErrorMessage name="address_home" component={TextError} />
                            </div>
                            {formik.values["type"] != "Remotely" ? <>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_person" className="form-label">{i18n.language == "ar" ? "شخص للتواصل في حالة الطوارئ" : "Emergency Contact Person"}</label>
                                    <Field name="emergency_contact_person" id="emergency_contact_person" className="form-control" />
                                    <ErrorMessage name="emergency_contact_person" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_mobile" className="form-label">{i18n.language == "ar" ? "رقم طوارئ" : "Emergency Contact Mobile"}</label>
                                    <Field name="emergency_contact_mobile" id="emergency_contact_mobile" className="form-control" />
                                    <ErrorMessage name="emergency_contact_mobile" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="emergency_contact_relation" className="form-label">{i18n.language == "ar" ? "العلاقة مع شخص الطوارئ" : "Emergency Contact Relation"}</label>
                                    <Field name="emergency_contact_relation" id="emergency_contact_relation" className="form-control" />
                                    <ErrorMessage name="emergency_contact_relation" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_number" className="form-label">{i18n.language == "ar" ? "رقم جواز السفر" : "Passport Number"}</label>
                                    <Field name="passport_number" id="passport_number" className="form-control" />
                                    <ErrorMessage name="passport_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء صلاحية جواز السفر" : "Passport Expiry Date"}</label>
                                    <Field name="passport_expiry_date" id="passport_expiry_date" className="form-control" />
                                    <ErrorMessage name="passport_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="passport_place_of_issue" className="form-label">{i18n.language == "ar" ? "مكان انشاء جواز السفر" : "Passport Place Of Issue"}</label>
                                    <Field name="passport_place_of_issue" id="passport_place_of_issue" className="form-control" />
                                    <ErrorMessage name="passport_place_of_issue" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_type" className="form-label">{i18n.language == "ar" ? "نوع الفيزة" : "Visa Type"}</label>
                                    <Field name="visa_type" id="visa_type" className="form-control" />
                                    <ErrorMessage name="visa_type" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء صلاحية الفيزة" : "Visa Expiry Date"}</label>
                                    <Field name="visa_expiry_date" id="visa_expiry_date" className="form-control" />
                                    <ErrorMessage name="visa_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_place_of_issue" className="form-label">{i18n.language == "ar" ? "مكان انشاء الفيزة" : "Visa Place Of Issue"}</label>
                                    <Field name="visa_place_of_issue" id="visa_place_of_issue" className="form-control" />
                                    <ErrorMessage name="visa_place_of_issue" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="visa_id_number" className="form-label">{i18n.language == "ar" ? "رقم الفيزة" : "Visa Id Number"}</label>
                                    <Field name="visa_id_number" id="visa_id_number" className="form-control" />
                                    <ErrorMessage name="visa_id_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="labor_card_issue_date" className="form-label">{i18n.language == "ar" ? "تاريخ منح بطاقة العمل" : "Labor Card Issue Date"}</label>
                                    <Field name="labor_card_issue_date" id="labor_card_issue_date" className="form-control" />
                                    <ErrorMessage name="labor_card_issue_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="labor_card_expiry_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء بطاقة العمل" : "Labor Card Expiry Date"}</label>
                                    <Field name="labor_card_expiry_date" id="labor_card_expiry_date" className="form-control" />
                                    <ErrorMessage name="labor_card_expiry_date" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="bank_name" className="form-label">{i18n.language == "ar" ? "اسم البنك" : "Bank Name"}</label>
                                    <Field name="bank_name" id="bank_name" className="form-control" />
                                    <ErrorMessage name="bank_name" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="account_holder_name" className="form-label">{i18n.language == "ar" ? "اسم صاحب الحساب" : "Account Holder Name"}</label>
                                    <Field name="account_holder_name" id="account_holder_name" className="form-control" />
                                    <ErrorMessage name="account_holder_name" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="account_number" className="form-label">{i18n.language == "ar" ? "رقم الحساب" : "Account Number"}</label>
                                    <Field name="account_number" id="account_number" className="form-control" />
                                    <ErrorMessage name="account_number" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="iban" className="form-label">{i18n.language == "ar" ? "iban" : "iban"}</label>
                                    <Field name="iban" id="iban" className="form-control" />
                                    <ErrorMessage name="iban" component={TextError} />
                                </div>
                                <div className="form-control dynamic-input">
                                    <label htmlFor="swift_code" className="form-label">{i18n.language == "ar" ? "swift code" : "swift code"}</label>
                                    <Field name="swift_code" id="swift_code" className="form-control" />
                                    <ErrorMessage name="swift_code" component={TextError} />
                                </div>


                            </> : <></>}






                            <div className="form-control dynamic-input">
                                <label htmlFor="official_date_of_employment" className="form-label">{i18n.language == "ar" ? "تاريخ التعيين" : "Official Date Of Employment"}</label>
                                <Field name="official_date_of_employment" id="official_date_of_employment" className="form-control" />
                                <ErrorMessage name="official_date_of_employment" component={TextError} />
                            </div>
                            <div className="form-control dynamic-input">

                                <label htmlFor="employer" className="form-label">{i18n.language == "ar" ? "الموّظف" : "Employer"}</label>
                                <Field name="employer" id="employer" className="form-control" />
                                <ErrorMessage name="employer" component={TextError} />
                            </div>
                            <div className="form-control">
                                <label htmlFor="role">
                                    {i18n.language == "ar" ? "القواعد" : "Roles"}
                                </label>
                                <Field name="role" id="role" className="form-select">
                                    {({ field, form }: any) => (
                                        <Multiselect
                                            onSelect={(selectedList, selectedItem) => {
                                                // Update the field value with IDs
                                                form.setFieldValue("role", selectedList.map((item: any) => item.id));
                                                // console.log(selectedItem)
                                            }}
                                            onRemove={(selectedList, removedItem) => {
                                                // Update the field value with IDs
                                                form.setFieldValue("role", selectedList.map((item: any) => item.id));
                                            }}
                                            {...field}
                                            // defaultValue={initialValues["role"].split(',').map(Number)}
                                            options={roles?.data?.data}
                                            displayValue="name"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="role" component={TextError} />
                            </div>
                            <div className="form-control">
                                <label htmlFor="manager_id">
                                    {i18n.language == "ar" ? "المدير" : "Manager"}
                                </label>
                                <Field as="select" name="manager_id" id="manager_id" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    {userOptions?.map((option: any) => (
                                        <option value={option.id}>{option.name}</option>
                                    ))}
                                </Field>
                                <ErrorMessage name="manager_id" component={TextError} />
                            </div>
                            <div className="form-control">
                                <label htmlFor="blood_type">
                                    {i18n.language == "ar" ? "زمرة الدم" : "Blood Type"}
                                </label>
                                <Field as="select" name="blood_type" id="blood_type" className="form-select">
                                    <option disabled value="" hidden>
                                        Select
                                    </option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                </Field>
                                <ErrorMessage name="blood_type" component={TextError} />
                            </div>
                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {text}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>}

            </div>
        </Modal>
    )
}

export default EditUserModal