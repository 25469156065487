import { BoxTitle, BoxWithShadow, Loading } from "../../../Components"
import "./MainPage.css"
import Img from "../../../Assets/Images/Employee of the month-amico.png"
import Quotation from "../../../Assets/Images/Dashboard/quotation-marks-in-speech-bubble-svgrepo-com.svg"
import Event from "../../../Assets/Images/Dashboard/event-date-and-time-symbol-svgrepo-com.svg"
import { Table } from "antd"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"
import { UserContext } from "../../../Context/UserContext"
const MainPage = () => {
    const { t, i18n } = useTranslation()
    const { isLoading, setIsLoading } = useContext(UserContext)
    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)

    }, [])
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];

    const columns = [
        {
            title: i18n.language == "ar" ? "اسم الناشر" : 'Publisher name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: i18n.language == "ar" ? "العنوان" : 'title',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: i18n.language == "ar" ? "تاريخ النشر" : 'Publish Date',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '',
            dataIndex: 'address',
            key: 'address',
            render: () => (<a className="show-news">{t("ShowNews")}</a>)
        },

    ];
    return (

        <>
            {isLoading == true ? <Loading /> : <div className="container-fluid main-page-con">
                <div className="row mb-5">
                    <div className="col-md-8 p-0 pr-1">
                        <BoxWithShadow additionalClass="announcement container-fluid">
                            <div className="row row-cols-2">
                                <div className="col">
                                    <div className="text">
                                        <h2>{t("CongratulationsBilal")} 🎉</h2>
                                        <p>{t("CongratulationsDesc")}
                                        </p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="announcement-col bg-full">
                                        <img src={Img} alt="" />
                                    </div>
                                </div>
                            </div>
                        </BoxWithShadow>
                    </div>
                    <div className="col-md-4">
                        <BoxWithShadow additionalClass="employee-of-month-img container-fluid bg-full" />
                    </div>

                </div>
                <div className="row mb-5">
                    <div className="col-md-6 p-0">
                        <div className="word-from-company">
                            <img src={Quotation} alt="" />
                            <p>{t("WordFromCompany")}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <BoxWithShadow additionalClass="event">
                            <img src={Event} alt="" />
                            <p>{t("WordFromCompany")}</p>
                        </BoxWithShadow>
                    </div>
                </div>
                <BoxWithShadow additionalClass="last-news">
                    <BoxTitle title={t("LastNews")} />
                    <div className="table-con">
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false} />
                    </div>
                </BoxWithShadow>
            </div>}

        </>
    )
}

export default MainPage