import { Field } from "formik"
import "./Select.css"
import { FC } from "react"
import { SelectOptionsTypes } from "../../Models/SelectOptionsTypes"
import { useTranslation } from "react-i18next"
type SelectProps = {
    name: string,
    label: {
        ar: string,
        en: string
    },
    options?: SelectOptionsTypes[]
}
const Select: FC<SelectProps> = ({ name, label, options }) => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className="form-control">
                <label htmlFor={name}>
                    {i18n.language == "ar" ? label.ar : label.en}
                </label>
                <Field as="select" name={name} id={name} className="form-select">
                    <option disabled value="" hidden>
                        Select
                    </option>
                    {options?.map(option => (
                        <option value={option.value}>
                            {option.label}
                        </option>
                    ))}

                </Field>
            </div>
        </>
    )
}

export default Select