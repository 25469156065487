import * as Yup from "yup"
const AddUserSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    image: Yup.string(),
    type: Yup.string()
        .required("Jop Type is required"),
    address_uae: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    mobile: Yup.string()
        .required("Mobile is required")
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols'),
    nationality: Yup.string()
        .required("Nationality is required")
        .min(3, 'Minimum 3 symbols')
        .max(30, 'Maximum 30 symbols'),
    home_country: Yup.string()
        .required("Home Country is required")
        .min(3, 'Minimum 3 symbols')
        .max(30, 'Maximum 30 symbols'),
    address_home:
        Yup.string()
            .required("Address Home is required")
            .min(3, 'Minimum 3 symbols')
            .max(60, 'Maximum 60 symbols'),
    emergency_contact_person: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(40, 'Maximum 40 symbols'),
    emergency_contact_mobile: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(40, 'Maximum 40 symbols'),
    emergency_contact_relation: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(40, 'Maximum 40 symbols'),
    passport_number: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(30, 'Maximum 30 symbols'),
    passport_expiry_date: Yup.string(),
    passport_place_of_issue: Yup.string(),
    visa_type: Yup.string(),
    visa_expiry_date: Yup.string(),
    visa_place_of_issue: Yup.string(),
    visa_id_numbers: Yup.string(),
    labor_card_issue_date: Yup.string(),
    labor_card_expiry_date: Yup.string(),
    employer: Yup.string()
        .required("Employer Is required"),
    bank_name: Yup.string(),
    account_holder_name: Yup.string(),
    account_number: Yup.string(),
    iban: Yup.string(),
    swift_code: Yup.string(),
    official_date_of_employment: Yup.string().required("Official Date Of Employment is required"),
    date_of_birth: Yup.string().required("Date Of Birth is required"),
    manger_id: Yup.string()

})
export default AddUserSchema