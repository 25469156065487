import { LinksProps } from "../../../../Models/LinksProps"
import { PiUsersThreeDuotone } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { FaArchway } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { CiCalendarDate } from "react-icons/ci";
const MainLinks: LinksProps[] = [

    {
        id: 1,
        to: "/dashboard",
        title: {
            en: "MainPage",
            ar: "الصفحة الرئيسية"
        },
        icon: FaArchway
    },

    {
        id: 2,
        to: "hrdashboard",
        title: {
            en: "HR-Dashbord",
            ar: "داشبورد"
        },
        icon: RxDashboard
    },
    {
        id: 3,
        to: "users",
        title: {
            en: "User-Mangement",
            ar: "ادارة المستخدمين"
        },
        icon: PiUsersThreeDuotone
    },
    {
        id: 4,
        to: "Profile-Mangement",
        title: {
            en: "Profile-Mangement",
            ar: "ادارة الحساب"
        },
        icon: CgProfile
    },
    {
        id: 5,
        to: "Holidays",
        title: {
            en: "Holidays",
            ar: "الاجازات"
        },
        icon: CiCalendarDate
    }
]
export default MainLinks