import "./Login.css"
import Logo from "../../Assets/Images/AETC Logo.svg"
import AuthScreens from "../../Assets/Images/Login/7015971.png"
import { FormikContainer } from "../../Components"
import { LoginInputs } from "../../Assets/Data"
import { schema } from "../../Utils/Validations/LoginSchema"
import Hand from "../../Assets/Images/Login/icons8-waving-hand-emoji-48.png"
import AuthServices from "../../Services/AuthServices"
import usePost from "../../Custom Hooks/usePost"
import Cookies from "js-cookie"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
const initialValues = {
    email: "",
    password: ""
}
const Login = () => {
    const { t, i18n } = useTranslation()
    const [handleSubmit, loading] = usePost(AuthServices.post, undefined, (res: any) => {
        Cookies.set("token", res?.data.data.token)
        Cookies.set("id", res?.data.data.id)
        localStorage.setItem("avatar", res?.data.data.image)
        localStorage.setItem("name", res?.data.data.name)
        const permissions = res?.data?.data?.role_permissions.flatMap((item: any) => item.permissions)
        Cookies.set("permissions", JSON.stringify(permissions))
        window.location.assign("/dashboard")
    })
    const textObj = {
        null: i18n.language == "en" ? "Continue" : "متابعة",
        loading: i18n.language == "en" ? "Please Wait ...." : "الرجاء الانتظار",
        success: i18n.language == "en" ? "login successfully" : "تم تسجيل الدخول بنجاح",
        faild: i18n.language == "en" ? "something went wrong" : "حدث خطأ ما"
    }
    return (
        <div className={`container-fluid login ${i18n.language == "ar" && "ar"}`}>
            <div className="row row-cols-1 row-cols-md-2 p-0 m-0">
                <div className="col p-0 left-col  flex-center-col">
                    <div className="text flex-center-col">
                        <h2>{t("SignIn")}</h2>
                        <p>{t("WelcomeToAetc")} <span><img src={Hand} alt="hand" /></span></p>
                    </div>
                    <FormikContainer
                        btnTextObj={textObj}
                        className="flex-center-col login-form"
                        initialValues={initialValues}
                        inputsArray={LoginInputs}
                        validateSchema={schema}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        needCheck={false}
                    />
                </div>
                <div className="col right-col bg-full flex-center-col p-0">
                    <div className="logo-con">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="auth-screens">
                        <img src={AuthScreens} alt="auth-screens" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login