import { useTranslation } from "react-i18next"
import { BoxWithShadow } from "../../../Components"
import useGet from "../../../Custom Hooks/useGet"
import UserService from "../../../Services/UserService"
import { MdOutlineKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import "./style.css"
import { NavLink, Outlet, useParams } from "react-router-dom";
const Index = () => {
    const params = useParams()
    const [data, loading] = useGet(params.id != null ? UserService.getUserById(params.id) : UserService.getUser())
    const { i18n, t } = useTranslation()
    const ProfileTo = params.id != null ? `/dashboard/userProfile/${params.id}` : "/dashboard/Profile-Mangement"
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid profile-banner ${i18n.language == "ar" && "ar"}`}>
                <div className="flex-start-center-row mb-2">
                    <div className={`img-profile-banner bg-full ${data?.data?.data?.image == null && "default-img"}`}
                        style={{
                            backgroundImage: data?.data?.data?.image != null && "default-img" ? `url(https://crmapi.aetc.ae/${data?.data?.data?.image})` : ""
                        }}></div>
                    <div className="flex-start-start-col info">
                        <h2>{data?.data?.data?.name}</h2>
                        <div className="flex-center">
                            <div className="heighlights-box flex-center-start-col ">
                                <div className="success-text flex-start-center">
                                    <span className="flex-center"><MdOutlineKeyboardDoubleArrowUp className="success-icon" /></span>
                                    4500$
                                </div>
                                <p className="m-0">Earnings</p>
                            </div>
                            <div className="heighlights-box flex-center-start-col ">
                                <div className="success-text flex-start-center">
                                    <span className="flex-center"><MdOutlineKeyboardDoubleArrowDown className="faild-icon" /></span>
                                    75
                                </div>
                                <p className="m-0">Projects</p>
                            </div>
                            <div className="heighlights-box flex-center-start-col ">
                                <div className="success-text flex-start-center">
                                    <span className="flex-center"><MdOutlineKeyboardDoubleArrowUp className="success-icon" /></span>
                                    60%
                                </div>
                                <p className="m-0">Success Rate</p>
                            </div>
                        </div>
                    </div>

                </div>
                <ul className="tabs">
                    <li>
                        <NavLink to={ProfileTo} end={true}>
                            {i18n.language == "ar" ? "الصفحة الشخصية" : "Profile"}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="edit-profile" end={true}>
                            {i18n.language == "ar" ? "تعديل" : "Edit"}
                        </NavLink>
                    </li>
                    {params.id != null ? <></> : <li>
                        <NavLink to="Change-Password" end={true}>
                            {i18n.language == "ar" ? "تغيير كلمة المرور" : "Change Password"}
                        </NavLink>
                    </li>}

                    <li>
                        <NavLink to="File-Mangement" end={true}>
                            {i18n.language == "ar" ? "الملفات" : "Documents"}
                        </NavLink>
                    </li>
                </ul>
            </BoxWithShadow>
            <Outlet />
        </>
    )
}

export default Index