import { LinksProps } from "../../Models/LinksProps"
import Logo from "../../Assets/Images/AETC Logo.svg"
import "./SideBar.css"
import { FC } from "react"
import { MenuItem, WelcomeBox } from "../../Components"
import { useTranslation } from "react-i18next"

type SideBarProps = {
    linksArray: LinksProps[]
}
const SideBar: FC<SideBarProps> = ({ linksArray }) => {
    const { t, i18n } = useTranslation()
    const links = linksArray.map(link => (
        <MenuItem link={link} key={link.id} />
    ))
    return (
        <div className={`col-md-3 sideBar  flex-col-between ${i18n.language == "ar" && "ar"}`}>
            <div className="flex-start-center-col">
                <div className="sideBar-header">
                    <div className="navbar-brand">
                        <img src={Logo} alt="" />
                    </div>
                </div>
                <hr className="horizontal dark mt-0" />
                <div className="flex-center">
                    <ul className="flex-center-col">
                        {links}
                    </ul>
                </div>
            </div>
            <WelcomeBox />

        </div>
    )
}

export default SideBar