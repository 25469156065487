import { ErrorMessage, Field, Form, Formik } from "formik"
import { BoxTitle, BoxWithShadow, TextError } from "../../../../Components"
import "./ChangePassword.css"
import { useTranslation } from "react-i18next"
import ChangePasswordSchema from "../../../../Utils/Validations/ChangePasswordSchema"
import clsx from 'clsx'
import { ToastContainer } from "react-toastify"
import usePost from "../../../../Custom Hooks/usePost"
import UserService from "../../../../Services/UserService"
const initialState = {
    old_password: "",
    password: "",
    password_confirmation: ""
}
const ChangePassword = () => {
    const { i18n, t } = useTranslation()
    const [handleSubmit, loading] = usePost(UserService.ChangePassword, {
        success: t("ChangePasswordSuccessfully"),
        error: t("Somethingwentwrong")
    })
    const OnSubmit = (values: any, onSubmitProps: any) => {
        // console.log(values)
        handleSubmit(values, onSubmitProps)
    }
    return (
        <>
            <BoxWithShadow additionalClass="profile-details container-fluid change-password-con">
                <div className="flex-between">
                    <BoxTitle title={t("ChangePassword")} />
                    {/* <MainBtn title="Edit Profile" /> */}
                </div>
                <Formik
                    initialValues={initialState}
                    onSubmit={OnSubmit}
                    validationSchema={ChangePasswordSchema}
                >
                    {
                        (formik) => {
                            { console.log(formik.errors) }
                            return (
                                <Form>
                                    <div className="flex-start-start-col profile-details-box">

                                        <div className="row">

                                            <div className="col">
                                                <h6>
                                                    {i18n.language == "ar" ? "كلمة المرور القديمة" : "Old Password"}

                                                </h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control dynamic-input">
                                                    <Field name="old_password" id="old_password" className={clsx(
                                                        'form-control bg-transparent',
                                                        { 'is-invalid': formik.touched.old_password && formik.errors.old_password },
                                                        {
                                                            'is-valid': formik.touched.old_password && !formik.errors.old_password,
                                                        }
                                                    )} />
                                                    <ErrorMessage name="old_password" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h6>
                                                    {i18n.language == "ar" ? "كلمة المرور " : "Password"}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control dynamic-input">
                                                    <Field name="password" id="password" className={clsx(
                                                        'form-control bg-transparent',
                                                        { 'is-invalid': formik.touched.password && formik.errors.password },
                                                        {
                                                            'is-valid': formik.touched.password && !formik.errors.password,
                                                        }
                                                    )} />
                                                    <ErrorMessage name="password" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h6>
                                                    {i18n.language == "ar" ? "تأكيد كلمة المرور" : "   Password Confirmation"}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <div className="form-control dynamic-input">
                                                    <Field name="password_confirmation" id="password_confirmation" className={clsx(
                                                        'form-control bg-transparent',
                                                        { 'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation },
                                                        {
                                                            'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                                                        }
                                                    )} />
                                                    <ErrorMessage name="password_confirmation" component={TextError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">

                                            </div>
                                            <div className="col">

                                                <div className='btn-form-container'>
                                                    <button
                                                        className='btn btn-primary'
                                                        disabled={formik.isSubmitting || !formik.isValid}>
                                                        {t("ChangePassword")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }

                </Formik>
                <ToastContainer />
            </BoxWithShadow>
        </>
    )
}

export default ChangePassword