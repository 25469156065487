import "./Calendar.css"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction";
const Calendar = ({ data }: {
    data: any
}) => {
    const events = [
        {
            title: "Bilal",
            start: new Date(2024, 8, 15), // August is 8th month
            end: new Date(2024, 8, 18),

        },
        {
            title: "Abd",
            start: new Date(2024, 8, 15), // August is 8th month
            end: new Date(2024, 8, 18),

        },
        {
            title: "Khaled",
            start: new Date(2024, 8, 20),
            end: new Date(2024, 8, 22),

        },
        {
            title: "Mr Anas Birthday",
            start: new Date(2024, 8, 25), // September is 8th month
            end: new Date(2024, 8, 25),
            className: "special-date", // Add this line
        }
    ];

    // Convert JavaScript Dates to ISO strings
    const formattedEvents = events.map(event => ({
        ...event,
        start: event.start.toISOString(),
        end: event.end.toISOString()
    }));


    const handleDate = (clickInfo: any) => {
        console.log("handleDate called", clickInfo);
        console.log("Clicked date:", clickInfo.date);
        console.log("Event details:", clickInfo.event);
    };

    return (
        <>
            <FullCalendar
                eventClassNames="calendar-event"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                events={formattedEvents}
                dateClick={handleDate}
            />
        </>
    )
}

export default Calendar