import { useParams } from "react-router-dom"
import { AddFileModal, BoxTitle, BoxWithShadow, Loading, MainBtn } from "../../../../Components"
import useGet from "../../../../Custom Hooks/useGet"
import UserService from "../../../../Services/UserService"
import "./FileMangement.css"
import { useContext, useState } from "react"
import { UserContext } from "../../../../Context/UserContext"
import PDFPhoto from "../../../../Assets/Images/Dashboard/pdf.svg"
import ImagePhoto from "../../../../Assets/Images/Dashboard/image-square-svgrepo-com.svg"
import DocPhoto from "../../../../Assets/Images/Dashboard/doc.svg"
import FileSvg from "../../../../Assets/Images/Dashboard/file-copy-svgrepo-com.svg"
import { HiOutlineDotsVertical } from "react-icons/hi";
import { saveAs } from 'file-saver';
import usePost from "../../../../Custom Hooks/usePost"
import { ToastContainer } from "react-toastify"
import { useTranslation } from "react-i18next"

const FileMangement = () => {
    const { t, i18n } = useTranslation()
    const { loginedUserId, setDepandancy, dependancy, isLoading } = useContext(UserContext)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [handleDelete,] = usePost(UserService.deleteFileById, {
        success: "Delete File Succefully",
        error: "Something Went Error"
    }, () => {
        setDepandancy(!dependancy)
    })
    const params = useParams()
    const [files,] = useGet(UserService.getFilesById(Object.keys(params).length > 0 ? params.id : loginedUserId), undefined, dependancy)
    const downloadFile = (file: string, desc: string) => {
        saveAs(file, desc)
    }
    const deleteFile = (id: number) => {
        handleDelete(id)
    }
    const filesBox = files?.data?.data?.user_files?.map((file: any) => {
        let image
        if (file.file_path.includes("pdf")) {
            image = PDFPhoto
        }
        else if (file.file_path.includes("jpg") || file.file_path.includes("jpeg")) {
            image = ImagePhoto
        }
        else if (file.file_path.includes("doc")) {
            image = DocPhoto
        }
        else {
            image = FileSvg
        }
        return (
            <div className="col mb-5">
                <BoxWithShadow additionalClass="file-white-con">
                    <img src={image} alt="" />
                    <h2>{file.desc}</h2>
                    <div className="options-files" data-bs-toggle="dropdown" aria-expanded="false">
                        <HiOutlineDotsVertical />
                    </div>
                    <ul className="dropdown-menu">
                        <li onClick={() => downloadFile(`https://crmapi.aetc.ae/${file.file_path}`, file.desc)}>Download</li>
                        <li onClick={() => deleteFile(file.id)}>Delete</li>
                    </ul>
                </BoxWithShadow>
            </div>
        )
    })
    return (
        <>
            {isLoading == true ? <Loading /> : <>
                <div className="file-mangement-con">
                    <div className="flex-between mb-5">
                        <BoxTitle title={t("FileMangement")} />
                        <MainBtn title={t("AddFile")} onClick={handleShow} />
                    </div>
                    <div className="row row-cols-3">
                        {filesBox}
                        <AddFileModal id={Object.keys(params).length > 0 ? params.id : loginedUserId} show={show} handleClose={handleClose} />
                    </div>
                </div>
                <ToastContainer />
            </>}
        </>

    )
}

export default FileMangement