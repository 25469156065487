import { FC, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import "./DynamicDropDownItems.css"
import { DropDownDataProps } from "../../Models/DropDownProps"
import UserService from "../../Services/UserService"
import usePost from "../../Custom Hooks/usePost"
import { UserContext } from "../../Context/UserContext"
import { useTranslation } from "react-i18next"
type DynamicDropDownItemsProps = {
    item: DropDownDataProps,
    record?: any,
    handleShowEditUser?: any,
    handleShowResetPassword?: any
}
const DynamicDropDownItems: FC<DynamicDropDownItemsProps> = ({ item, record, handleShowEditUser, handleShowResetPassword }) => {
    const { setDepandancy, dependancy, getUserId } = useContext(UserContext)
    const { i18n, t } = useTranslation()
    const [handleRequest] = usePost(UserService.deactiveUser, {
        success: "Deactivate Succefully",
        error: "Something Went Error"
    }, () => {
        setDepandancy(!dependancy)
    })
    const navigate = useNavigate()
    const handleSignOut = () => {
        Cookies.remove("token")
        localStorage.removeItem("avatar")
        window.location.assign("/")
    }
    const DeactiveUser = (id: number) => {
        handleRequest(id)
    }

    const handleRowNavigate = (id: number) => {
        navigate(`/dashboard/userProfile/${id}`)
    }
    if (item.isSignOut == true) {
        return <li onClick={handleSignOut}>{i18n.language == "ar" ? item.title.ar : item.title.en}</li>
    }
    else if (item.to) {
        return <li>
            <Link to={item.to}>{i18n.language == "ar" ? item.title.ar : item.title.en}</Link>
        </li>
    }
    else if (item.isEdit == true) {
        return <li onClick={() => {
            getUserId(record.id)
            handleShowEditUser()
        }}>
            {i18n.language == "ar" ? item.title.ar : item.title.en}
        </li>
    }
    else if (item.isReset == true) {
        return <li onClick={() => {
            handleShowResetPassword()
            getUserId(record.id)
        }}>
            {i18n.language == "ar" ? item.title.ar : item.title.en}
        </li>
    }
    else if (item.isDeactive == true) {
        return <li onClick={() => DeactiveUser(record.id)}>
            {record.is_active == 0 && i18n.language == "ar" ? item.title.arAc :
                record.is_active == 0 && i18n.language == "en" ? item.title.enAc :
                    record.is_active == 1 && i18n.language == "ar" ? item.title.arDe :
                        record.is_active == 1 && i18n.language == "en" && item.title.enDe
            }
            {i18n.language == "ar" ? item.title.ar : item.title.en}
        </li>
    }
    else if (item.isViewProfile == true) {
        return <li onClick={() => handleRowNavigate(record.id)}>
            {i18n.language == "ar" ? item.title.ar : item.title.en}
        </li>
    }
    return null
}

export default DynamicDropDownItems