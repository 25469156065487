import { FC } from "react"

type loading = null | string | boolean

type Text = {
    null: string,
    success: string,
    faild: string,
    loading: string
}

const GetLoadingText = (loading: loading, text: Text): string => {
    if (loading == null) {
        return text.null
    }
    else if (loading == true) {
        return text.success
    }
    else if (loading == false) {
        return text.faild
    }
    else {
        return text.loading
    }

}
export default GetLoadingText