import { FC } from "react"
import "./Input.css"
import { ErrorMessage, Field } from "formik"
import { TextError } from "../"
import { useTranslation } from "react-i18next"
type InputProps = {
    name: string,
    label: {
        ar: string,
        en: string
    },
    type?: string

}
const Input: FC<InputProps> = ({ name, label, type, ...rest }) => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className="form-control dynamic-input">
                <label htmlFor={name} className="form-label">{i18n.language == "ar" ? label.ar : label.en}</label>
                <Field name={name} id={name} type={type} {...rest} className="form-control" />
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    )
}

export default Input