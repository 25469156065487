import { NavLink } from "react-router-dom"
import { LinksProps } from "../../Models/LinksProps"
import "./MenuItem.css"
import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"
type MenuItem = {
    link: LinksProps
}
const MenuItem: FC<MenuItem> = ({ link }) => {
    const { i18n, t } = useTranslation()
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const linksWithPermissions = () => {
        if (permissions.includes("View Main Page") && link.title.en == "MainPage") {

            return <li>
                <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                    <span className="flex-center">
                        {React.createElement(link.icon)}
                    </span>
                    {i18n.language == "en" ? link.title.en : link.title.ar}
                </NavLink>
            </li>
        }
        else if (permissions.includes("View HR Dashboard Page") && link.title.en == "HR-Dashbord") {
            return <li>
                <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                    <span className="flex-center">
                        {React.createElement(link.icon)}
                    </span>
                    {i18n.language == "en" ? link.title.en : link.title.ar}
                </NavLink>
            </li>
        }
        else if (permissions.includes("View User Management Page" && link.title.en == "User-Mangement")) {
            return <li>
                <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                    <span className="flex-center">
                        {React.createElement(link.icon)}
                    </span>
                    {i18n.language == "en" ? link.title.en : link.title.ar}
                </NavLink>
            </li>

        }
        else if (permissions.includes("View Profile Management Page") && link.title.en == "Profile-Mangement") {
            return <li>
                <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                    <span className="flex-center">
                        {React.createElement(link.icon)}
                    </span>
                    {i18n.language == "en" ? link.title.en : link.title.ar}
                </NavLink>
            </li>
        }
        else if (permissions.includes("View Leaves Page") && link.title.en == "Holidays") {
            return <li>
                <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                    <span className="flex-center">
                        {React.createElement(link.icon)}
                    </span>
                    {i18n.language == "en" ? link.title.en : link.title.ar}
                </NavLink>
            </li>
        }
        else {
            return <></>
        }
    }
    return (
        <>
            {linksWithPermissions()}
        </>
    )
}

export default MenuItem