import { FC } from "react"
import { AddUserInputsProps } from "../../Models/AddUserInputsProps"
import { FormikControl } from "../"
import { SelectOptionsTypes } from "../../Models/SelectOptionsTypes"

type IsInputVisibleProps = {
    input: AddUserInputsProps,
    options?: SelectOptionsTypes[],
    typeValue: string
}
const IsInputVisible: FC<IsInputVisibleProps> = ({ input, options, typeValue }) => {
    if (input.isMain) {
        return <FormikControl
            control={input.control}
            type={input.type}
            name={input.name}
            label={input.label}
            options={options} />
    }
    else if (input.isMain == null && typeValue == "Remotely") {
        return <></>
    }
    else if (input.isMain == null && typeValue != "Remotely") {
        return <FormikControl
            control={input.control}
            type={input.type}
            name={input.name}
            label={input.label}
            options={options} />
    }


    return <></>
}

export default IsInputVisible