import { useContext, useEffect, useState } from "react";
import { BoxTitle, BoxWithShadow, Calendar, DynamicTable, Loading, MainBtn, TextError } from "../../../Components"
import "./Holidays.css"
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../../Context/UserContext";
import usePost from "../../../Custom Hooks/usePost";
import LeavesServices from "../../../Utils/Validations/LeavesServices";
import useGet from "../../../Custom Hooks/useGet";
const initialValues = {
    start_date: "",
    end_date: "",
    back_to_work_date: "",
    type: "",
    position: ""
}
const Holidays = () => {
    const { t, i18n } = useTranslation()
    const [showHolidays, setShowHolidays] = useState(false);
    const handleCloseHolidays = () => setShowHolidays(false);
    const handleShowHolidays = () => setShowHolidays(true);
    const [loading, setLoading] = useState(true)
    const { isLoading, setIsLoading, setDepandancy, dependancy } = useContext(UserContext)
    const [handleAdd,] = usePost(LeavesServices.post, {
        success: "Leave Has Been Added Succefully",
        error: "Something Went Error"
    }, () => {
        handleCloseHolidays()
        setDepandancy(!dependancy)
    })
    const [data,] = useGet(LeavesServices.get(), undefined, dependancy)

    const onSubmit = (values: any, onSubmitProps: any) => {
        handleAdd(values, onSubmitProps)
    }
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])
    const dataSource = [
        {
            key: '1',
            name: 'Bilal',
            Sickleave: 32,
            Administrativeleave: 10,
            Leavewithoutpay: 10
        },
        {
            key: '2',
            name: 'Abd',
            Sickleave: 42,
            Administrativeleave: 22,
            Leavewithoutpay: 15
        },
        {
            key: '3',
            name: 'Ruba',
            Sickleave: 55,
            Administrativeleave: 52,
            Leavewithoutpay: 0
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: i18n.language == "ar" ? "اجازة مرضية" : "Sick leave",
            dataIndex: 'Sickleave',
            key: 'Sickleave',
        },
        {
            title: i18n.language == "ar" ? "اجازة ادارية" : "Administrative leave",
            dataIndex: 'Administrativeleave',
            key: 'Administrativeleave',
        },
        {
            title: i18n.language == "ar" ? "اجازة بدون اجر" : "Leave without pay",
            dataIndex: 'Leavewithoutpay',
            key: 'Leavewithoutpay',
        },
    ];

    return (
        <>
            {isLoading == true ? <Loading /> : <>
                <BoxWithShadow additionalClass="holidays-con holidays-main-con">
                    <div className="flex-between mb-5">
                        <BoxTitle title={t("holidays")} />
                        <MainBtn title={t("AddLeave")} onClick={handleShowHolidays} />
                    </div>
                    <Calendar data={data} />
                    <Modal show={showHolidays} onHide={handleCloseHolidays} id="AddLeave" className="users-modal" centered>
                        <div className="modal-header">
                            <BoxTitle title={t("holidays")} />
                            <button type="button" className="btn-close" onClick={handleCloseHolidays}></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            >


                                {(formik) => (
                                    <Form encType="multipart/form-data">
                                        <div className="form-control dynamic-input">
                                            <label htmlFor="start_date" className="form-label">{i18n.language == "ar" ? "تاريخ بدأ الاجازة" : "Start Date"}</label>
                                            <Field name="start_date" id="start_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("start_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="date_of_birth" component={TextError} />
                                        </div>
                                        <div className="form-control dynamic-input">
                                            <label htmlFor="end_date" className="form-label">{i18n.language == "ar" ? "تاريخ انتهاء الاجازة" : "End Date"}</label>
                                            <Field name="end_date" id="end_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("end_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="end_date" component={TextError} />
                                        </div>
                                        <div className="form-control dynamic-input">
                                            <label htmlFor="back_to_work_date" className="form-label">{i18n.language == "ar" ? "تاريخ العودة للعمل" : "Back To Work Date"}</label>
                                            <Field name="back_to_work_date" id="back_to_work_date" className="form-control" >
                                                {({ field, form }: any) => (
                                                    <DatePicker {...field}
                                                        dateFormat="yyyy/MM/dd"
                                                        className="form-control"
                                                        showYearDropdown
                                                        selected={field.value}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                // Convert Date object to string in YYYY/MM/DD format
                                                                const formattedDate = moment(val).format('YYYY/MM/DD');

                                                                // Update the form field value
                                                                form.setFieldValue("back_to_work_date", formattedDate);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="end_date" component={TextError} />
                                        </div>
                                        <div className="form-control">
                                            <label htmlFor="type">
                                                {i18n.language == "ar" ? "نوع الاجازة" : "Leaves Type"}
                                            </label>
                                            <Field as="select" name="type" id="type" className="form-select">
                                                <option disabled value="" hidden>
                                                    Select
                                                </option>
                                                <option value="1"> {i18n.language == "ar" ? "اجازة مرضية" : "Sick leave"}</option>
                                                <option value="2"> {i18n.language == "ar" ? "اجازة ادارية" : "Administrative leave"}</option>
                                                <option value="3"> {i18n.language == "ar" ? "اجازة بدون اجر" : "Leave without pay"}</option>
                                            </Field>
                                            <ErrorMessage name="type" component={TextError} />
                                        </div>
                                        {formik.values.type == "1" && <div className="mb-3 mt-3">
                                            <label htmlFor="formFile" className="form-label">Medical report</label>
                                            <input className="form-control taqrer" type="file" id="formFile" />
                                        </div>}
                                        <div className="form-control dynamic-input">

                                            <label htmlFor="position" className="form-label">{i18n.language == "ar" ? "المنصب الوظيفي" : "Position"}</label>
                                            <Field name="position" id="position" className="form-control" />
                                            <ErrorMessage name="position" component={TextError} />
                                        </div>
                                        <div className='btn-form-container'>
                                            <button
                                                className='btn btn-primary'
                                                disabled={formik.isSubmitting || !formik.isValid}>
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </Modal>
                    <ToastContainer />
                </BoxWithShadow>
                <BoxWithShadow additionalClass="mt-5 holidays-con holidays-main-con">
                    <BoxTitle title={t("holidays")} />
                    <DynamicTable
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </BoxWithShadow>
            </>}
        </>

    )
}

export default Holidays