import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik"
import BoxTitle from "../BoxTitle/BoxTitle"
import "./AddFileModal.css"
import { useTranslation } from "react-i18next"
import { RiPencilFill } from "react-icons/ri"
import TextError from "../TextError/TextError"
import FileSvg from "../../Assets/Images/Dashboard/file-copy-svgrepo-com.svg"
import { useContext, useState } from "react"
import { UserContext } from "../../Context/UserContext"
import usePost from "../../Custom Hooks/usePost"
import UserService from "../../Services/UserService"
import { Modal } from "react-bootstrap"
import * as Yup from "yup"
const schema = Yup.object().shape({
    files: Yup.array().of(
        Yup.object().shape({
            desc: Yup.string().required('descripation is required'),
            file: Yup.mixed().required('File is required')
        })
    )

})

const AddFileModal = ({ id, show, handleClose }: { id: string | undefined, show: any, handleClose: any }) => {
    const { dependancy, setDepandancy } = useContext(UserContext)
    const [fileName, setFileName] = useState<string>("")
    const [handleAdd,] = usePost(UserService.addFile, {
        success: "Add File Succefully",
        error: "Something Went Error"
    }, () => {
        setDepandancy(!dependancy)
        handleClose()
    })
    const initialValues = {
        files: [
            {
                desc: "",
                file: ""
            }
        ],
        user_id: id
    }
    const { i18n, t } = useTranslation()
    const onSubmit = (values: any, onSubmitProps: any) => {
        handleAdd(values, onSubmitProps)
    }

    return (
        <Modal show={show} onHide={handleClose} id="AddUser" className={`users-modal add-file-modal ${i18n.language == "ar" && "ar"}`} centered>
            <div className="modal-header">
                <BoxTitle title={t("AddFile")} />
                <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                >

                    {(formik) => (
                        <Form encType="multipart/form-data">
                            <>
                                <Field name="image1">
                                    {({ field, form }: FieldProps<any>) => (
                                        <>
                                            <div className='img-label'>{i18n.language == "ar" ? "ملف" : "File"}</div>
                                            <label htmlFor="imgUpload" className="img-input-con bg-full">
                                                <img src={FileSvg} alt="" className="mb-2 img-file" />
                                                <span>{fileName != null && fileName}</span>
                                                <div className="change-img-btn flex-center">
                                                    <RiPencilFill className="icon" />
                                                </div>
                                                <input id="imgUpload" {...field} type="file" onChange={(e) => {
                                                    setFileName(e.target.files![0].name)
                                                    formik.setFieldValue("files[0].file", e.target.files![0])
                                                }} />
                                            </label>
                                        </>
                                    )}
                                </Field>
                                <Field name="files[0].file" type="hidden" />
                                <ErrorMessage name="files[0].file" />
                            </>
                            <div className="form-control dynamic-input">
                                <label htmlFor="files[0].desc" className="form-label">{i18n.language == "ar" ? "الوصف" : "Description"}</label>
                                <Field name="files[0].desc" id="files[0].desc" className="form-control" />
                                <ErrorMessage name="files[0].desc" component={TextError} />
                            </div>

                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {t("AddFile")}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

        </Modal>
    )
}

export default AddFileModal