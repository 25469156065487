import { FC } from "react"
import "./BoxTitle.css"

type BoxTitleProps = {
    title: string
}

const BoxTitle: FC<BoxTitleProps> = ({ title }) => {
    return (
        <h4 className="box-title">{title}</h4>
    )
}

export default BoxTitle